import React, { FC, useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { AppContext } from 'src/app/App'
import { LinkContainer, LinkStyled } from './styles'

type Props = {
  handleClose?: Function
  label: string
  routeTo: string
}

const MenuLink: FC<Props> = ({
  handleClose,
  label,
  routeTo
}) => {
  const { isDesktop } = useContext(AppContext)
  const { pathname } = useLocation()

  const isActive = pathname.includes(routeTo) ? true : false

  return (
    <LinkContainer 
      isActive={isActive} 
      onClick={isDesktop ? () => {} : handleClose} 
      role='link'
      aria-label={label}>
      <NavLink to={routeTo} style={LinkStyled}>
        {label}
      </NavLink>
    </LinkContainer>
  )
}

export default MenuLink
