import { color, font, padding } from 'src/styles/themes'
import styled from 'styled-components'

export const LinkStyled = {
  textDecoration: 'unset',
  color: 'inherit',
}

type LinkProps = {
  isActive: boolean
}

export const LinkContainer = styled.div<LinkProps>`
  font-size: ${font.paragraph};
  font-family: ${font.primaryFontLight};
  letter-spacing: ${font.letterSpacing1};
  color: ${color.dark};
  font-weight: ${font.weight400};
  padding-bottom: ${padding.medium};
  ${(props: LinkProps) =>
    props.isActive &&
    `
      color: ${color.red};
    `}
  @media screen and (min-width: 960px) {
    letter-spacing: 0;
    padding-bottom: 0;
    padding-right: ${padding.medium};
  }
`
