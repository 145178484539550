import React, { Fragment, FunctionComponent } from 'react'
import { LightLogoFilled } from 'src/assets/images'
import { Button, Footer, MenuLink, NavigationBar } from 'src/components/common'
import { MobileMenu } from 'src/components/features'
import { useFrame, MenuConst } from './base'

const Frame: FunctionComponent = ({ children }) => {
  const {
    buttonLabel,
    handleClose,
    isEnglish,
    isNotDev,
    isMobileMenuActive,
    setIsEnglish,
    setMobileMenuActive,
  } = useFrame()

  const translateButton = <Button label={buttonLabel} handleClick={() => setIsEnglish(!isEnglish)} />

  const navigationPages = (
    <Fragment>
      <MenuLink
        label={isEnglish ? MenuConst.HOME : MenuConst.HOME_VIET}
        routeTo='/tntax-dev' 
        handleClose={handleClose} />
      {/* change later */}
      <MenuLink
        label={isEnglish ?  MenuConst.ABOUT : MenuConst.ABOUT_VIET}
        routeTo='/about' 
      handleClose={handleClose} />
      <MenuLink
        label={isEnglish ? MenuConst.SERVICES : MenuConst.SERVICES_VIET}
        routeTo='/services' 
      handleClose={handleClose} />
      <MenuLink
        label={isEnglish ? MenuConst.TEAM : MenuConst.TEAM}
        routeTo='/team' 
      handleClose={handleClose} />
      <MenuLink
        label={isEnglish ? MenuConst.CONTACT : MenuConst.CONTACT_VIET}
        routeTo='/contact' 
      handleClose={handleClose} />
      {translateButton}
    </Fragment>
  )
  
  const navigation = (
    isMobileMenuActive ?
      <MobileMenu
        setMobileMenuActive={setMobileMenuActive}
        isMobileMenuActive={isMobileMenuActive}> 
        {navigationPages}
      </MobileMenu>
      : <NavigationBar
        logo={LightLogoFilled}
        setMobileMenuActive={setMobileMenuActive}
        isMobileMenuActive={isMobileMenuActive}>
          {navigationPages}
        </NavigationBar>
  )

  return (
    <Fragment>
      {!isNotDev && navigation}
      {!isMobileMenuActive && children}
      {!isNotDev && <Footer />}
    </Fragment>
  )
}

export default Frame
