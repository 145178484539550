import React, { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import { HoldingPage } from "src/components/features";
import {
  AboutPage,
  ContactPage,
  HomePage,
  ServicesPage,
  TeamPage,
} from "src/components/pages";
import { Frame } from "src/components/template";

const AppRoutes: FunctionComponent = () => {
  return (
    <Frame>
      <Routes>
        <Route path={"/"} element={<HoldingPage />} />
        <Route path={"home"} element={<HoldingPage />} />
        {/* <Route
          path={'tntax-dev'}
          element={<HomePage />}
        />
        <Route
          path={'about'}
          element={<AboutPage />}
        />
        <Route
          path={'contact'}
          element={<ContactPage />}
        />
        <Route
          path={'services'}
          element={<ServicesPage />}
        />
        <Route
          path={'Team'}
          element={<TeamPage />}
        /> */}
      </Routes>
    </Frame>
  );
};

export default AppRoutes;
