import React, { FC } from 'react'
import { ButtonContainer } from './styles'

type Props = {
  handleClick: Function
  label: string
}

const Button: FC<Props> = ({
  handleClick,
  label,
}) => {

  return (
    <ButtonContainer 
      onClick={handleClick} 
      role='link'
      aria-label={label}>
      {label}
    </ButtonContainer>
  )
}

export default Button
