import { color, font, padding } from 'src/styles/themes'
import styled from 'styled-components'

export const FooterLogo = styled.div`
  padding: ${padding.medium} 0;
`

export const DetailsContainer = styled.div`
`

export const Details = styled.div`
  padding: ${padding.medium} 0;
`

type MailToProps = {
  isDarkTheme: boolean
}

export const MailTo = styled.a`
  color: ${color.black};
  font-family: ${font.primaryFont};
  letter-spacing: ${font.letterSpacing03};
  font-size: ${font.paragraph};
  margin: 0 0 ${padding.small} 0;
  text-decoration: unset;
  font-weight: ${font.weight300};
  ${(props: MailToProps) =>
    props.isDarkTheme &&
    `
      color: ${color.white};
    `}
`

type PhoneProps = {
  isDarkTheme: boolean
}

export const Phone = styled.p<PhoneProps>`
  color: ${color.black};
  font-family: ${font.primaryFont};
  letter-spacing: ${font.letterSpacing03};
  font-size: ${font.paragraph};
  margin: 0 0 ${padding.small} 0;
  font-weight: ${font.weight300};
  ${(props: PhoneProps) =>
    props.isDarkTheme &&
    `
      color: ${color.white};
    `}
`

export const FormContainer = styled.div`
`

export const ContactTitle = styled.h1`
  font-size: ${font.titleMobile};
  font-weight: ${font.weight400};
  margin: 0;
  @media screen and (min-width: 750px) {
    font-size: ${font.titleDesktop};
  }
`
