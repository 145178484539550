import React, { FC, useContext } from 'react'
import { 
  DesktopNavigation,
  LogoContianer,
  MenuIcon,
  NavigationBarContainer,
  NavigationBarDiv
} from './styles'
import { MobMenu } from 'src/assets/images'
import { AppContext } from 'src/app/App'
import { NavLink } from 'react-router-dom'

type Props = {
  logo: string
  setMobileMenuActive: Function
  isMobileMenuActive: boolean
}

const NavigationBar: FC<Props> = ({
  children,
  logo,
  isMobileMenuActive,
  setMobileMenuActive,
}) => {
  const showMobMenu = () => setMobileMenuActive(!isMobileMenuActive)
  const { isDesktop } = useContext(AppContext)

  const mobileNav = (
    <MenuIcon onClick={showMobMenu}>
      <img src={MobMenu} width={'22px'} alt='Mobile menu' />
    </MenuIcon>
  )

  return (
    <NavigationBarContainer>
      <NavigationBarDiv>
        <LogoContianer>
          <NavLink to='/tntax-dev' role='link'> 
          {/* change later */}
            <img src={logo} width={'100%'} alt='T.N. tax logo' />
          </NavLink>
        </LogoContianer>
        {isDesktop ?
          <DesktopNavigation>
            {children}
          </DesktopNavigation>
          : mobileNav}
      </NavigationBarDiv>
    </NavigationBarContainer>
  )
}

export default NavigationBar