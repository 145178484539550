import { color, font, padding } from 'src/styles/themes'
import styled from 'styled-components'

export const FooterContainer = styled.section`
  height: auto;
  background-color: ${color.primary};
  padding: ${padding.medium};
  @media screen and (min-width: 960px) {
    padding: ${padding.large} ${padding.pageContainer};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${padding.medium};
  }
`
