import React, { createContext, FunctionComponent, useState } from 'react'
import Helmet from 'react-helmet'
import { BrowserRouter } from 'react-router-dom'
import AppRoutes from 'src/routes/AppRoutes'
import { useApp } from './base'

const defaultApp: any = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  isEnglish: true,
}

export const AppContext = createContext(defaultApp)

const App: FunctionComponent = () => {
  const { windowWidth } = useApp()

  const [isEnglish, setIsEnglish] = useState(true)

  return (
    <AppContext.Provider
      value={{
        isMobile: windowWidth < 750,
        isTablet: windowWidth < 960 && windowWidth > 750,
        isDesktop: windowWidth > 960,
        isEnglish,
        setIsEnglish
      }}>
      <Helmet>
        <title>Tax Advice Chartered Accountant Nottingham - TNTax</title>
        <meta name='keywords' content='vietnamese accountant, vietnamese accountant uk, vietnamese accountant nottingham, accountancy services, tax accountant, nottingham accountancy, uk accountancy, personal tax advisor, tax return, tax review, self employed tax ' />
        <meta name='description' content='Chartered tax advisers providing Tax Return and Accountancy services' />
      </Helmet>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </AppContext.Provider>
  )
}

export default App
