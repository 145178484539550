import { padding } from 'src/styles/themes'
import styled from 'styled-components'

export const ContactContainer = styled.section`
  padding: ${padding.large} ${padding.medium};
  @media screen and (min-width: 750px) {
    padding: 150px ${padding.medium} ${padding.large};
  }
  @media screen and (min-width: 960px) {
    padding: 250px ${padding.pageContainer};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${padding.medium};
  }
`
