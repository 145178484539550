import { TeamImage } from 'src/assets/images'
import styled from 'styled-components'

export const TeamContainer = styled.section``

export const TeamImageSection = styled.section`
  /* The image used */
  background-image: url(${TeamImage});

  height: 150vh;
  background-position: unset;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (min-width: 750px) {
    height: 100vh;
  }
  @media screen and (min-width: 960px) {
    /* Set a specific height */
    min-height: 1500px;
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
  }
`

