import { color, font, padding } from 'src/styles/themes'
import styled from 'styled-components'

export const AboutContainer = styled.section`
  display: grid;
  grid-template-rows: auto auto auto;
`

export const AboutDesktop = styled.div`
  padding: 250px ${padding.medium} ${padding.medium};
  display: grid;
  background-color: ${color.white};
  @media screen and (min-width: 750px) {
    padding: 150px ${padding.medium} ${padding.medium};
    grid-template-columns: 35% 1fr;
    grid-gap: ${padding.medium};
  }
  @media screen and (min-width: 960px) {
    padding: 250px ${padding.pageContainer} ${padding.large};
  }
`

export const AboutImageContainer = styled.div`
  padding: ${padding.medium};
  grid-row: 3;
  @media screen and (min-width: 960px) {
    padding: ${padding.large} ${padding.pageContainer};
  }
`

export const AboutText = styled.h1`
  font-size: ${font.titleMobile};
  font-weight: ${font.weight400};
  margin: 0;
  grid-row: 2;
  padding: 0 ${padding.medium} ${padding.small};
  @media screen and (min-width: 750px) {
    padding: 0;
    font-size: ${font.titleDesktop};
    grid-row: unset;
  }
`

export const AboutParagraph = styled.div`
  font-size: ${font.paragraph};
  font-family: ${font.primaryFontLight};
  font-weight: ${font.weight400};
  line-height: ${font.lineHeight15};
  grid-row: 4;
`

export const AboutParagraphContainer = styled.div`
  padding: ${padding.small} ${padding.medium} ${padding.large};
  @media screen and (min-width: 750px) {
    padding: 0;
  }
`

export const AboutParagraphTitle = styled.h1`
  font-size: ${font.paragraph};
  color: ${color.primary};
  font-family: ${font.primaryFontLight};
  font-weight: ${font.weight400};
  padding: ${padding.medium} ${padding.medium} 0;
  grid-row: 1;
  @media screen and (min-width: 750px) {
    padding: 0;
  }
`
