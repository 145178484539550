import { useContext, useState } from 'react'
import { useLocation } from 'react-router'
import { AppContext } from 'src/app/App'

export enum MenuConst {
  HOME = 'Home',
  HOME_VIET = 'Nhà',
  ABOUT = 'About',
  ABOUT_VIET = 'Về',
  SERVICES = 'Services',
  SERVICES_VIET = 'Dịch vụ',
  TEAM = 'Team',
  TEAM_VIET = 'Đội',
  CONTACT = 'Contact',
  CONTACT_VIET = 'Tiếp xúc',
}

export const useFrame = () => {
  const { isEnglish, isDesktop, setIsEnglish } = useContext(AppContext)
  const { pathname } = useLocation() // will delete later

  const isNotDev = pathname.includes('home') || pathname.length === 1 ? true : false // will delete later
  const [isMobileMenuActive, setMobileMenuActive] = useState(false)

  const handleClose = () => setMobileMenuActive(!isMobileMenuActive)

  const buttonLabel = isEnglish ? 'Dịch sang tiếng việt' : 'Translate to English' 

  return {
    buttonLabel,
    handleClose,
    isEnglish,
    isDesktop,
    isNotDev,
    isMobileMenuActive,
    setIsEnglish,
    setMobileMenuActive,
  }
}
