import React, { FC } from 'react'
import { Close } from 'src/assets/images'
import { 
  CloseIcon,
  MobileMenuContainer,
  RoutesContainer
} from './styles'

type Props = {
  setMobileMenuActive: Function
  isMobileMenuActive: boolean
}

const MobileMenu: FC<Props> = ({
  children,
  isMobileMenuActive,
  setMobileMenuActive
}) => {
  const closeMobMenu = () => setMobileMenuActive(!isMobileMenuActive)

  return (
    <MobileMenuContainer>
      <CloseIcon onClick={closeMobMenu}>
        <img src={Close} width={'16px'} alt='Close' />
      </CloseIcon>
      <RoutesContainer>
        {children}
      </RoutesContainer>
    </MobileMenuContainer>
  )
}

export default MobileMenu
