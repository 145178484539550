import { useEffect, useState } from 'react'

export const useApp = () => {
  const [windowWidth, setWindowWidth] = useState(0)

  const updateWindowSize = () => setWindowWidth(window.innerWidth)

  useEffect(() => {
    updateWindowSize()

    window.addEventListener('resize', updateWindowSize)
    return () => window.removeEventListener('resize', updateWindowSize)
  }, [])
  
  return {
    windowWidth
  }
}
