import { color, border, font, padding } from 'src/styles/themes'
import styled from 'styled-components'

const borderMobile = '3px'

export const QuoteContainer = styled.div`
  height: 144px;
  background-color: ${color.white};
  position: absolute;
  top: 112px;
  width: 100%;
  @media screen and (min-width: 750px) {
    height: 274px;
  }
  @media screen and (min-width: 960px) {
    height: 298px;
  }
`

export const QuoteStyled = styled.div`
  font-size: 52px;
  font-family: ${font.secondaryFont};
  width: 92vw;
  margin: ${padding.large} 0 0 ${padding.medium};
  @media screen and (min-width: 750px) {
    font-size: 115px;
  }
  @media screen and (min-width: 960px) {
    width: 70%;
    margin: 66px 0 0 ${padding.pageContainer};

  }
`

export const TextStyled = styled.h4`
  line-height: 1.2em;
  margin: 0;
  font-weight: ${font.weight400};
`

export const TextDecorationOne = styled.div`
  margin-left: ${padding.medium};
  top: -8px;
  height: ${borderMobile};
  width: 257px;
  background-color: ${color.primary};
  position: relative;
  @media screen and (min-width: 750px) {
    height: ${border.underline};
    top: -20px;
    width: 532px;
  }
  @media screen and (min-width: 960px) {
    margin-left: ${padding.pageContainer};
  }
`

export const TextDecorationTwo = styled.div`
  background-color: ${color.primary};
  position: relative;
  margin-left: ${padding.medium};
  height: ${borderMobile};
  bottom: 74px;
  width: 47px;
  @media screen and (min-width: 750px) {
    height: ${border.underline};
    bottom: 162px;
    width: 106px;
  }
  @media screen and (min-width: 960px) {
    margin-left: ${padding.pageContainer};
  }
`

export const TextDecorationThree = styled.div`
  background-color: ${color.primary};
  position: relative;
  margin-left: ${padding.medium};
  height: ${borderMobile};
  bottom: 77px;
  left: 76px;
  width: 110px;
  @media screen and (min-width: 750px) {
    height: ${border.underline};
    left: 168px;
    bottom: 166px;
    width: 224px;
  }
  @media screen and (min-width: 960px) {
    margin-left: ${padding.pageContainer};
  }
`

// Introduction
export const IntroductionContainer = styled.section`
  height: auto;
  background-color: ${color.white};
  padding: 0 ${padding.medium} ${padding.medium};
  font-size: ${font.titleMobile};
  @media screen and (min-width: 960px) {
    padding: 0 ${padding.pageContainer} ${padding.large};
  }
`

export const IntroductionStyled = styled.h4`
  font-weight: ${font.weight400};
  margin: 0;
  padding: ${padding.medium} 0;
  white-space: pre-line;
`

export const IntroductionLogo = styled.div`
  padding-top: ${padding.large};
`

// Testimonial
export const TestimonialContainer = styled.section`
  background-color: ${color.primary};
  overflow: hidden;
  display: flex;
  `

export const TestimonialCard = styled.div`
  padding: 0 0 ${padding.large};
  overflow: hidden;
`

export const SlideShowSlider = styled.div`
  white-space: nowrap;
  transition: ease 1000ms;
`

export const Slide = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  @media screen and (min-width: 750px) {
    height: 240px;
  }
  @media screen and (min-width: 960px) {
    height: 340px;
  }
`

export const SlideShowDots = styled.div`
  text-align: center;
`

type SlideShowDot = {
  active: boolean
}

export const SlideShowDotContainer = styled.div`
  display: inline-block;
  &:focus-visible {
    padding: 4px;
    outline: ${color.white} auto 2px;
  }
`

export const SlideShowDot = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 ${padding.xsmall} 0px;
  background-color: ${color.white};
  ${(props: SlideShowDot) =>
    props.active &&
    `
      background-color: ${color.red};
    `}
`

export const TestimonialText = styled.h1`
  color: ${color.white};
  font-family: ${font.primaryFont};
  font-weight: ${font.weight300};
  font-size: 36px;
`

export const TestimonialAuthor = styled.h2`
  color: ${color.white};
  font-family: ${font.secondaryFont};
  font-weight: ${font.weight400};
  letter-spacing: ${font.letterSpacing05};
  font-size: ${font.paragraph};
`

export const QuoteIcon = styled.div`
  padding-top: ${padding.small};
  width: 50px;
`

export const Testimonial = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: auto auto auto;
  white-space: normal;
  padding: ${padding.medium} ${padding.medium} 0;
  @media screen and (min-width: 750px) {
    grid-gap: ${padding.medium};
    padding: 0 ${padding.medium};
  }
  @media screen and (min-width: 960px) {
    padding: 0 ${padding.pageContainer};
  }
`

// Services
export const ServicesContainer = styled.section`
  padding: ${padding.medium};
  @media screen and (min-width: 960px) {
    padding: ${padding.medium} ${padding.pageContainer};
  }
`
