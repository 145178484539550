import styled from 'styled-components'
import { color, padding } from 'src/styles/themes'

export const MobileMenuContainer = styled.div`
  background-color: ${color.grey};
  height: 100vh;
`

export const CloseIcon = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: 42px;
  padding-top: 48px;
  cursor: pointer;
  position: relative;
`

export const RoutesContainer = styled.div`
  text-align: center;
  padding: ${padding.medium} 0;
`
