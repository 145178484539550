import { color, font, padding } from 'src/styles/themes'
import styled from 'styled-components'

export const ButtonContainer = styled.div`
  font-size: 14px;
  font-family: ${font.primaryFontLight};
  text-align: center;
  color: ${color.white};
  font-weight: ${font.weight400};
  padding: ${padding.xsmall} ${padding.small};
  border-radius: 8px;
  background-color: ${color.primary};
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  margin: auto;
  @media screen and (min-width: 960px) {
    width: 80px;
  }
`
