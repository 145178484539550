import React, { FunctionComponent, useContext } from 'react'
import {
  Details,
  DetailsInput,
  Form,
  FormContainer,
  FormTitle,
  Message,
  MessageContainer,
  Submit,
} from './styles'
import { useContactForm } from './base'
import { AppContext } from 'src/app/App'

type Props = {
  isDarkTheme: boolean
}

const ContactForm: FunctionComponent<Props> = ({ isDarkTheme }) => {
  const { isEnglish } = useContext(AppContext)

  const title = isEnglish ? 'Send us a message' : 'Gửi tin nhắn cho chúng tôi'
  const name = isEnglish ? 'Name' : 'Tên'
  const email = isEnglish ? 'Email' : 'E-mail'
  const message = isEnglish ? 'Type your message here...' : 'Gõ tin nhắn của bạn ở đây'
  const submit = isEnglish ? 'Submit' : 'Gửi đi'

  const {
    handleSubmit
  } = useContactForm()

  return (
    <FormContainer>
      <FormTitle isDarkTheme={isDarkTheme}>
        {title}
      </FormTitle>
      <Form onSubmit={handleSubmit}>
        <DetailsInput>
          <Details
            isDarkTheme={isDarkTheme}
            type={'text'}
            id='name'
            name='from_name'
            placeholder={name}
            role='textbox'
            required />
          <Details
            isDarkTheme={isDarkTheme}
            type={'email'}
            id='email'
            name='from_email'
            placeholder={email}
            role='textbox'
            required />
        </DetailsInput>
        <MessageContainer>
          <Message 
            isDarkTheme={isDarkTheme}
            type={'text'}
            id='message'
            name='message'
            placeholder={message}
            role='textbox'
            required />
        </MessageContainer>
        <Submit 
          type='submit'
          aria-label='Submit'
          role='button'>{submit}</Submit>
      </Form>
    </FormContainer>
  )
}

export default ContactForm
