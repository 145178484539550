import React, { FunctionComponent } from 'react'
import { DarkLogoFilled } from 'src/assets/images'
import {
  HoldingPageContainer,
  HoldingTextContainer,
  HoldingText,
  Logo,
  logoStyles
} from './styles'

const HoldingPage: FunctionComponent = () => {
  return (
    <HoldingPageContainer>
    <HoldingTextContainer>
      <HoldingText>UNDER</HoldingText>
      <HoldingText>CONSTRUCTION</HoldingText>
    </HoldingTextContainer>
      <Logo>
        <img src={DarkLogoFilled} style={logoStyles} alt='T.N. tax logo' />
      </Logo>
    </HoldingPageContainer>
  )
}

export default HoldingPage
