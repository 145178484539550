import { color, font, padding } from 'src/styles/themes'
import styled from 'styled-components'

export const ServicesImageContainer = styled.div`
  padding: ${padding.medium} 0;
  @media screen and (min-width: 750px) {
    padding: 0;
  }
`

export const ServicesIntroduction = styled.div`
  display: grid;
  background-color: ${color.white};
  @media screen and (min-width: 750px) {
    grid-gap: ${padding.large};
    padding: 150px 0 ${padding.medium};
    grid-template-columns: 45% 1fr;
  }
  @media screen and (min-width: 960px) {
    padding: 250px 0 ${padding.large};
  }
`

export const ServicesContainer = styled.section`
  display: grid;
  padding: ${padding.medium} ${padding.medium} ${padding.large};
  @media screen and (min-width: 960px) {
    padding: ${padding.medium} ${padding.pageContainer} ${padding.large};
  }
`

export const ServicesParagraph = styled.div`
  font-size: ${font.paragraph};
  font-family: ${font.primaryFontLight};
  font-weight: ${font.weight400};
  line-height: ${font.lineHeight15};
`

export const ServicesParagraphContainer = styled.div`
  padding: ${padding.small} 0;
  @media screen and (min-width: 750px) {
    padding: ${padding.small} ${padding.medium} ${padding.large};
    padding: 0;
  }
`

export const ServicesParagraphTitle = styled.h1`
  font-size: ${font.paragraph};
  color: ${color.primary};
  font-family: ${font.primaryFontLight};
  font-weight: ${font.weight400};
  padding: ${padding.medium} 0 0;
  margin-top: 0;
  @media screen and (min-width: 750px) {
    padding: 0;
  }
`

export const ServicesImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

// Services comp
type ServicesTitleProps = {
  pageTheme: boolean
}

export const ServicesTitle = styled.h1`
  font-size: 22px;
  font-family: ${font.secondaryFont};
  letter-spacing: ${font.letterSpacing03};
  font-weight: ${font.weight400};
  text-align: center;
  padding: 0 ${padding.small} ${padding.large};
  color: ${color.primary};
  @media screen and (min-width: 750px) {
    font-size: 26px;
    margin-bottom: auto;
  }
  @media screen and (min-width: 960px) {
    font-size: 36px;
  }
  ${(props: ServicesTitleProps) =>
    props.pageTheme === true &&
    `
      margin-bottom: 0;
      text-align: left;
      font-size: 24px;
      padding: 0;
      @media screen and (min-width: 750px) {
        margin-bottom: auto;
      }
    `}
`

export const ServiceAbout = styled.h2`
  font-size: ${font.paragraph};
  font-family: ${font.primaryFontLight};
  line-height: ${font.lineHeight15};
  font-weight: ${font.weight400};
  ${(props: ServicesTitleProps) =>
    props.pageTheme === true &&
    `
      margin-bottom: 0;
      @media screen and (min-width: 750px) {
        margin-bottom: auto;
      }
    `}
`

export const ServiceContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid ${color.mediumGrey};
  ${(props: ServicesTitleProps) =>
    props.pageTheme === true &&
    `
      border: unset;
    `}
`


export const Services = styled.div`
  display: grid;
  padding-bottom: 80px;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  @media screen and (min-width: 750px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${(props: ServicesTitleProps) =>
    props.pageTheme === true &&
    `
      grid-template-columns: 1fr;
      @media screen and (min-width: 750px) {
        grid-gap: ${padding.large};
      }
      @media screen and (min-width: 960px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    `}
`
