import { color, font, padding } from 'src/styles/themes'
import styled from 'styled-components'

export const TeamContainer = styled.section`
  height: auto;
  background-color: ${color.white};
  position: absolute;
  top: 112px;
  padding: ${padding.medium};
  display: grid;
  @media screen and (min-width: 750px) {
    padding: ${padding.large} ${padding.medium};
    height: auto;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${padding.medium};
  }
  @media screen and (min-width: 960px) {
    padding: ${padding.large} ${padding.pageContainer};
  }
`

export const Title = styled.h1`
  font-size: ${font.paragraph};
  color: ${color.primary};
  font-family: ${font.primaryFontLight};
  font-weight: ${font.weight400};
`

export const Name = styled.h1`
  font-size: ${font.titleDesktop};
  margin: 0;
  font-family: ${font.secondaryFont};
  font-weight: ${font.weight400};
  white-space: pre-line;
`

export const Qualifications = styled.h2`
  font-size: ${font.paragraph};
  font-family: ${font.primaryFont};
  font-weight: bold;
  white-space: pre-line;
`

export const TeamParagraph = styled.h2`
  font-size: ${font.paragraph};
  font-family: ${font.primaryFontLight};
  font-weight: ${font.weight400};
  line-height: ${font.lineHeight15};
  white-space: pre-line;
`

export const TeamProfile = styled.div`
  @media screen and (min-width: 750px) {
    padding-top: ${padding.large};
  }
  @media screen and (min-width: 960px) {
    padding-top: 80px;
  }
`

export const ProfileImage = styled.div`
  text-align: center;
  padding: ${padding.medium} 0;
`
