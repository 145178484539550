import { color, font, padding } from 'src/styles/themes'
import styled from 'styled-components'

export const FormContainer = styled.div`
  padding-top: ${padding.medium};
  @media screen and (min-width: 960px) {
    padding-top: 0;
  }
`

export const Form = styled.form`
  padding: ${padding.small} 0;
  @media screen and (min-width: 960px) {
    padding: ${padding.medium} 0;
  }
`

type FormTitleProps = {
  isDarkTheme: boolean
}

export const FormTitle = styled.p`
  color: ${color.black};
  font-family: ${font.primaryFont};
  letter-spacing: ${font.letterSpacing03};
  font-size: ${font.paragraph};
  margin: 0 0 ${padding.small} 0;
  ${(props: FormTitleProps) =>
    props.isDarkTheme &&
    `
      color: ${color.white};
    `}
`

export const DetailsInput = styled.div`
  grid-gap: ${padding.small};
  display: grid;
  @media screen and (min-width: 960px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: ${padding.medium};
  }
`

type DetailsProps = {
  isDarkTheme: boolean
}

export const Details = styled.input`
  font-family: ${font.primaryFont};
  width: -webkit-fill-available;
  height: 32px;
  font-size: ${font.paragraph};
  background-color: ${color.white};
  border: 1px solid ${color.black};
  color: ${color.black};
  padding: ${padding.small} 0 ${padding.small} ${padding.medium};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${color.black};
  }
  :-ms-input-placeholder {
    color: ${color.black};
  }
  ${(props: DetailsProps) =>
    props.isDarkTheme &&
    `
    background-color: ${color.primary};
    color: ${color.white};
    border: 1px solid ${color.white};
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${color.white};
    }
    :-ms-input-placeholder {
      color: ${color.white};
    }
    `}
`

type MessageProps = {
  isDarkTheme: boolean
}

export const Message = styled.textarea`
 font-family: ${font.primaryFont};
  font-size: ${font.paragraph};
  background-color: ${color.white};
  border: 1px solid ${color.black};
  color: ${color.black};
  padding: ${padding.small} 0 ${padding.small} ${padding.medium};
  width: -webkit-fill-available;
  height: 100px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${color.black};
  }
  :-ms-input-placeholder {
    color: ${color.black};
  }

  ${(props: MessageProps) =>
    props.isDarkTheme &&
    `
    background-color: ${color.primary};
    color: ${color.white};
    border: 1px solid ${color.white};
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${color.white};
    }
    :-ms-input-placeholder {
      color: ${color.white};
    }
    `}
`

export const MessageContainer = styled.div`
  padding: ${padding.small} 0;
`

export const Submit = styled.button`
  font-family: ${font.primaryFont};
  height: 42px;
  font-size: ${font.paragraph};
  background-color: ${color.primary};
  border: 1px solid ${color.white};
  width: 100%;
  color: ${color.white};
  cursor: pointer;
`
