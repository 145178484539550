import Swal from 'sweetalert2'
import emailjs from 'emailjs-com'
import { color } from 'src/styles/themes'
import './styles.css'
import { useContext } from 'react'
import { AppContext } from 'src/app/App'

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID || ''
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID || ''
const USER_ID = process.env.REACT_APP_USER_ID || ''

export const useContactForm = () => {
  const { isEnglish } = useContext(AppContext)

  const successMessage = isEnglish ? 'Message sent successfully' : 'Đã gửi tin nhắn thành công'
  const errorMessage = isEnglish ? 'Oops, something went wrong' : 'Oops, có gì đó không ổn'

  const handleSubmit = async (event: any) => {
    event.preventDefault()
  
    emailjs.sendForm(
      SERVICE_ID,
      TEMPLATE_ID,
      event.target,
      USER_ID
    ).then((result) => {
      console.log(result.text)
      Swal.fire({
        icon: 'success',
        title: successMessage,
        confirmButtonColor: color.primary
      })
    }, (error) => {
      console.log(error.text)
      Swal.fire({
        icon: 'error',
        title: errorMessage,
        text: error.text
      })
    })
  
    event.target.reset()
  }

  return {
    handleSubmit
  }
}
