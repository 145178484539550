import React, { Fragment, FunctionComponent, useContext } from 'react'
import { WhiteLogoTransparent } from 'src/assets/images'
import { ContactForm } from 'src/components/common'
import {
  ContactTitle,
  Details,
  DetailsContainer,
  FooterLogo,
  FormContainer,
  MailTo,
  Phone,
} from './styles'
import { ContactTheme } from './base'
import { AppContext } from 'src/app/App'

type Props = {
  theme: ContactTheme
}

const Contact: FunctionComponent<Props> = ({
  theme
}) => {
  const { isEnglish } = useContext(AppContext)
  
  const isDarkTheme = theme === ContactTheme.DARK

  const mailtoEmail = `mailto:${process.env.REACT_APP_EMAIL_TO}`

  const email = isEnglish ? 'Email:' : 'E-mail:'
  const phone = isEnglish ? 'Phone:' : 'Điện thoại:'
  const title = isEnglish ? 'For any inquiries, please call or email us. Alternatively you can fill in the following contact form.' : 'Ting viet'

  return (
    <Fragment>
      <DetailsContainer>
        {isDarkTheme ?
          <FooterLogo>
            <img src={WhiteLogoTransparent} width={'140px'} alt='T.N. tax logo' />
          </FooterLogo> :
          <ContactTitle>
            {title}
          </ContactTitle>}
        <Details>
          <MailTo href={mailtoEmail} isDarkTheme={isDarkTheme} role='link'>
            {email} {process.env.REACT_APP_EMAIL}
          </MailTo>
          <Phone isDarkTheme={isDarkTheme}>{phone} {process.env.REACT_APP_PHONE}</Phone>
        </Details>
      </DetailsContainer>
      <FormContainer>
        <ContactForm isDarkTheme={isDarkTheme} />
      </FormContainer>
    </Fragment>
  )
}

export default Contact
