import React, { FunctionComponent } from 'react'
import { ContactTheme } from '../contact/base'
import Contact from '../contact/Contact'
import {
  FooterContainer,
} from './styles'

const Footer: FunctionComponent = () => {
  return (
    <FooterContainer>
      <Contact theme={ContactTheme.DARK} />
    </FooterContainer>
  )
}

export default Footer
