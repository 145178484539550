import { color, border } from 'src/styles/themes'
import styled from 'styled-components'
import { PrimaryImage, SecondaryImage } from 'src/assets/images/'

export const HomePageContainer = styled.div`
`

export const PrimaryImageDiv = styled.section`
  /* The image used */
  background-image: url(${SecondaryImage});

  height: 70vh;
  background-position: unset;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (min-width: 750px) {
    height: 100vh;
  }
  @media screen and (min-width: 960px) {
    /* Set a specific height */
    min-height: 1000px;
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
  }
`

export const SecondaryImageDiv = styled.section`
  /* The image used */
  background-image: url(${PrimaryImage});

  height: 50vh;
  background-position: unset;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (min-width: 960px) {
    height: 100vh;
    /* Set a specific height */
    min-height: 1000px;
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
  }
`
