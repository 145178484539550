import styled from 'styled-components'
import { color, font, padding } from 'src/styles/themes'

export const HoldingTextContainer = styled.div `
  margin: 0 auto;
  margin-bottom: 0;
`

export const HoldingText = styled.p `
  color: ${color.white};
  font-size: 50px;
  text-align: center;
  margin: 0;
  font-family: ${font.primaryFont};
`

export const HoldingPageContainer = styled.div `
  background-color: ${color.primary};
  height: 100vh;
  display: table-cell;
  vertical-align: middle;
  width: 100vw;
  @media screen and (min-width: 750px) {
    padding-bottom: 300px;
  }
  @media screen and (min-width: 960px) {
    padding-bottom: 0;
  }
`

export const Logo = styled.div `
  text-align: center;
  @media screen and (min-width: 750px) {
    padding-left: ${padding.medium};
  }
`
export const logoStyles = {
  width: '50%',
}

