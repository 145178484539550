export const font = {
  primaryFont: 'Helvetica, sans-serif',
  primaryFontLight: 'Helvetica-light, sans-serif',
  secondaryFont: 'Times New Roman, Times, serif',
  weight400: '400',
  weight300: '300',
  paragraph: '18px',
  titleMobile: '32px',
  titleDesktop: '55px',
  letterSpacing03: '0.03em',
  letterSpacing05: '0.05em',
  letterSpacing1: '1em',
  lineHeight15: '1.5em'
}
export const color = {
  black: '#000000',
  dark: '#1E242E',
  grey: '#f7f7f7',
  mediumGrey: '#E2E3E2',
  primary: '#161348',
  red: '#C86567',
  white: '#FFFFFF',
}

export const padding = {
  xsmall: '8px',
  small: '12px',
  medium: '24px',
  large: '40px',
  pageContainer: '228px'
}

export const size = {
  icon: '22px'
}

export const border = {
  underline: '4px'
}
