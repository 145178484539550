export const ServicesArray = [
  {
    name: 'Financial Accounts Preparation',
    about: 'We’re registered to carry out audits by the ICAEW and have experienced, hands-on audit leaders supported by the Big Four experience of our partners.'
  },
  {
    name: 'Book-keeping and Cloud Accounting',
    about: 'We’re registered to carry out audits by the ICAEW and have experienced, hands-on audit leaders supported by the Big Four experience of our partners.'
  },
  {
    name: 'Payroll Administration',
    about: 'We’re registered to carry out audits by the ICAEW and have experienced, hands-on audit leaders supported by the Big Four experience of our partners.'
  },
  {
    name: 'Business Tax Management',
    about: 'We’re registered to carry out audits by the ICAEW and have experienced, hands-on audit leaders supported by the Big Four experience of our partners.'
  },
  {
    name: 'Private Client Tax Service',
    about: 'We’re registered to carry out audits by the ICAEW and have experienced, hands-on audit leaders supported by the Big Four experience of our partners.'
  },
  {
    name: 'Targeted Business Advice',
    about: 'We’re registered to carry out audits by the ICAEW and have experienced, hands-on audit leaders supported by the Big Four experience of our partners.'
  },
  {
    name: 'Experienced And Expert Teams',
    about: 'We’re registered to carry out audits by the ICAEW and have experienced, hands-on audit leaders supported by the Big Four experience of our partners.'
  },
  {
    name: 'Inheritance tax planning',
    about: 'We’re registered to carry out audits by the ICAEW and have experienced, hands-on audit leaders supported by the Big Four experience of our partners.'
  },
]

export const VietnameseServicesArray = [
  {
    name: 'Chuẩn bị tài khoản tài chính',
    about: 'Tieng viet'
  },
  {
    name: 'Giữ sổ sách và Kế toán đám mây',
    about: 'Tieng viet'
  },
  {
    name: 'Quản trị tiền lương',
    about: 'Tieng viet'
  },
  {
    name: 'Quản lý thuế doanh nghiệp',
    about: 'Tieng viet'
  },
  {
    name: 'Dịch vụ thuế khách hàng cá nhân',
    about: 'Tieng viet'
  },
  {
    name: 'Lời khuyên Kinh doanh Nhắm mục tiêu',
    about: 'Tieng viet'
  },
  {
    name: 'Đội ngũ có kinh nghiệm và chuyên gia',
    about: 'Tieng viet'
  },
  {
    name: 'Lập kế hoạch thuế thừa kế',
    about: 'Tieng viet'
  },
]

export enum ThemeConst {
  PAGE = 'page',
  FEATURE = 'feature'
}
