import styled from 'styled-components'
import { color, padding } from 'src/styles/themes'

export const NavigationBarContainer = styled.div`
  @media screen and (min-width: 750px) {
    position: fixed;
    width: 100%;
    z-index: 1;
  }
`

export const NavigationBarDiv = styled.div`
  height: 112px;
  background-color: ${color.white};
  display: flex;
  @media screen and (min-width: 960px) {
    padding: 0 ${padding.pageContainer};
  }
`

export const MenuIcon = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: ${padding.large};
  cursor: pointer;
`

export const LogoContianer = styled.div`
  margin: auto auto auto ${padding.medium};
  width: 50%;
  @media screen and (min-width: 750px) {
    width: 24%;
  }
  @media screen and (min-width: 960px) {
    margin: auto auto auto 0;
    width: 16%;
  }
`

export const DesktopNavigation = styled.div`
  display: flex;
  align-items: center;
`
